<template>
  <div>
    <Assistant_teacher_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="กรรมการประเมินครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="3" class="pa-1">
              <div>
                <v-autocomplete
                  outlined
                  label="วันเดือนปีที่บรรจุ"
                  :items="groupDateTimeApps"
                  item-text="dateTimeApp"
                  item-value="dateTimeApp"
                  @change="pertem_assis_teachDateApp()"
                  v-model="dateTimeAppSelect"
                ></v-autocomplete>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="pa-1">
              <v-autocomplete
                outlined
                label="ค้นหา วิทยาลัย :"
                :items="colleges"
                item-text="college_name"
                item-value="college_code"
                v-model="college_select"
                @change="pertem_assis_teachCollegeQueryAll()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" class="pa-1">
              <v-radio-group row>
                <v-radio
                  label="แสดงทั้งหมด"
                  color="info"
                  value="show"
                  @click="pertem_assis_teachQueryAll()"
                ></v-radio>              
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="3" class="pa-1">
              <div class="text-center">
                <v-btn
                  to="/admin/print_assistantcommittee/"
                  target="_blank"
                  rounded
                  color="info"
                >
                  <v-icon>mdi-printer</v-icon> พิมพ์รายชื่อกรรมการประเมิน (1.1)
                  รวม</v-btn
                >
                <v-btn
                  v-if="dateTimeAppSelect"
                  @click="print_assistantcommittee2()"
                  rounded
                  color="info"
                >
                  <v-icon>mdi-printer</v-icon> พิมพ์รายชื่อกรรมการประเมิน (1.2)
                  วันบรรจุ</v-btn
                >
                <v-btn
                  to="/admin/print_assistantcommittee_vip/"
                  target="_blank"
                  rounded
                  color="info"
                >
                  <v-icon>mdi-printer</v-icon> พิมพ์รายชื่อผู้่ทรงคุณวุฒิ (2.1)
                  รวม</v-btn
                >
                <v-btn
                  v-if="dateTimeAppSelect"
                  @click="print_assistantcommittee3()"
                  rounded
                  color="info"
                >
                  <v-icon>mdi-printer</v-icon> พิมพ์รายชื่อผู้่ทรงคุณวุฒิ (2.2)
                  วันบรรจุ</v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          filled
        />
        <v-data-table
          :headers="headers"
          :items="pertem_assis_teachs"
          class="elevation-1"
          :loading="loading"
          :search="search"
        >
          <template v-slot:item.pertem_assistantFile="{ item }">
            <div v-if="item.pertem_assistantFile">
              <div>
                <v-btn
                  color="info"
                  icon
                  :href="'/HRvecfiles/' + item.pertem_assistantFile"
                  target="_blank"
                  ><v-icon>mdi-printer</v-icon></v-btn
                >
                <v-btn
                  color="red"
                  icon
                  @click="pertem_assistantFileCC(item.id_card)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
              <div v-if="item.pertem_assistantFileCC === 'CC'">
                <v-chip dark color="red">คำขอยกเลิกแนบไฟล์</v-chip>
              </div>
            </div>
            <div v-else>
              <v-btn
                rounded
                color="warning"
                @click="pertem_assistantFileAdd(item.id_card)"
              >
                <v-icon class="pr-3">mdi-file-plus</v-icon> แนบคำสั่ง
              </v-btn>
            </div>
          </template>

          <template v-slot:item.brith_day="{ item }">
            {{
              item.brith_day + "/" + item.brith_month + "/" + item.brith_year
            }}
          </template>
          <template v-slot:item.appoin_day="{ item }">
            {{
              item.appoin_day + "/" + item.appoin_month + "/" + item.appoin_year
            }}
          </template>

          <template v-slot:item.evaluate_s="{ item }">
            <v-chip
              color="green"
              dark
              v-if="item.report_id_card === item.id_card"
            >
              <h2>
                <v-icon>mdi-checkbox-marked-circle</v-icon> ส่งผลการประเมิน
              </h2>
            </v-chip>
            <v-chip
              v-else-if="
                item.last_time_ass === '1' ||
                  item.last_time_ass === '2' ||
                  item.last_time_ass === '3' ||
                  item.last_time_ass === '4'
              "
              color="info"
              @click.stop="evaluate_assistant(item.id_card)"
              dark
            >
              <h2>{{ item.last_time_ass }}</h2>
            </v-chip>
            <v-chip
              v-else
              dark
              color="red"
              @click.stop="evaluate_assistant(item.id_card)"
            >
              <h2>
                <v-icon class="pa-1">mdi-cursor-pointer</v-icon>
                ไม่ได้เริ่มประเมิน
              </h2>
            </v-chip>
          </template>

          <template v-slot:item.prints="{ item }">
            <v-chip
              color="info"
              dark
              v-if="item.report_id_card === item.id_card"
            >
              <h2><v-icon>mdi-printer</v-icon> พิมพ์รายงาน</h2>
            </v-chip>
            <v-chip
              v-else-if="
                item.last_time_ass === '1' ||
                  item.last_time_ass === '2' ||
                  item.last_time_ass === '3' ||
                  item.last_time_ass === '4'
              "
              color="info"
              dark
            >
              <h2>อยู่ระหว่างการประเมิน</h2>
            </v-chip>
            <v-chip v-else color="red" dark>
              <h2>ไม่ได้เริ่มประเมิน</h2>
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              @click.stop="pertem_assis_teachEdit(item.id_rc)"
            >
              mdi-pencil
            </v-icon>
          </template>

          <template v-slot:item.cancels="{ item }">
            <v-icon color="red" @click.stop="evaluate_assistant(item.id_card)">
              mdi-cancel
            </v-icon>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </base-material-card>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import Assistant_teacher_bar from "../../components/admin/assistant_teacher_bar.vue";

export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      addpertem_assis_teachdialog: false,
      editpertem_assis_teachdialog: false,
      deletepertem_assis_teachdialog: false,
      evaluate_assistantdialog: false,
      assistant_teacherdialog: false,
      assistant_teacherEditdialog: false,
      assistant_teacherConfirmdialog: false,
      cancel_assistant_teacher_reportdialog: false,
      pertem_assistantFileCCdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      pertem_assis_teachs: [],

      search: "",
      pagination: {},
      headers: [
        { text: "รหัสสถานศึกษา", align: "left", value: "college_code" },
        { text: "สถานศึกษา", align: "left", value: "college_name" },
        { text: "รหัสบัตรประชาชน", align: "left", value: "id_card" },
        { text: "คำนำหน้าชื่อ", align: "left", value: "title_s" },
        { text: "ชื่อ", align: "left", value: "frist_name" },
        { text: "สกุล", align: "left", value: "last_name" },
        { text: "ตำแหน่ง", align: "center", value: "position_name" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "id_position" },
        {
          text: "คำสั่งแต่งตั้ง",
          align: "center",
          value: "pertem_assistantFile"
        },
        {
          text: "ผู้ทรงคุณวุฒิ",
          align: "center",
          value: "assistantcommitteeQualifiedname1"
        },
        {
          text: "กรรมการและเลขานุการ",
          align: "center",
          value: "committername2"
        }
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      data_syslog: {},
      colleges: [],
      groupDateTimeApps: [],
      college_select: "",
      dateTimeAppSelect: ""
    };
  },
  async mounted() {
    await this.sweetAlertLoading();
    let result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });
    this.colleges = result.data;
    this.groupDateTimeAppQuery();
    Swal.close();
  },
  methods: { 

    async print_assistantcommittee2() {
      let dateTimeApp = this.dateTimeAppSelect.split("-");
      let appoin_day = dateTimeApp[0];
      let appoin_month = dateTimeApp[1];
      let appoin_year = dateTimeApp[2];
      window.open(
        "#/admin/print_assistantcommittee2/?appoin_day=" +
          appoin_day +
          "&appoin_month=" +
          appoin_month +
          "&appoin_year=" +
          appoin_year,
        "_blank"
      );
    },

    async print_assistantcommittee3() {
      let dateTimeApp = this.dateTimeAppSelect.split("-");
      let appoin_day = dateTimeApp[0];
      let appoin_month = dateTimeApp[1];
      let appoin_year = dateTimeApp[2];
      window.open(
        "#/admin/print_assistantcommittee4/?appoin_day=" +
          appoin_day +
          "&appoin_month=" +
          appoin_month +
          "&appoin_year=" +
          appoin_year,
        "_blank"
      );
    },

    async pertem_assis_teachDateApp() {
      await this.sweetAlertLoading();
      let dateTimeApp = this.dateTimeAppSelect.split("-");
      let appoin_day = dateTimeApp[0];
      let appoin_month = dateTimeApp[1];
      let appoin_year = dateTimeApp[2];
      let result = await this.$http.post("pertem_assis_teach.php", {
        ApiKey: this.ApiKey,
        appoin_day: appoin_day,
        appoin_month: appoin_month,
        appoin_year: appoin_year
      });
      this.pertem_assis_teachs = result.data;
      Swal.close();
    },
    async pertem_assis_teachQueryAll(){
      await this.sweetAlertLoading();  
      let result = await this.$http.post("pertem_assis_teach.php", {
        ApiKey: this.ApiKey,
        admin:"ok"
      });
      this.pertem_assis_teachs = result.data;
      Swal.close();
    },
    async groupDateTimeAppQuery() {
      let result = await this.$http.post("pertem_assis_teach.php", {
        ApiKey: this.ApiKey,
        groUPDATETimeApp: "Ok"
      });
      this.groupDateTimeApps = result.data;
    },

    async pertem_assis_teachCollegeQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("pertem_assis_teach.php", {
          ApiKey: this.ApiKey,
          position_name: "ครูผู้ช่วย",
          user_name: this.college_select
        })
        .finally(() => (this.loading = false));
      this.pertem_assis_teachs = result.data;
      
    },

   
    async pertem_assistantFileCC(id_card) {
      let result = await this.$http.post("pertem_assis_teach.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editpertem_assis_teach = result.data;
      this.pertem_assistantFileCCdialog = true;
    },
    async cancel_assistant_teacher_report() {
      this.cancel_assistant_teacher_reportdialog = true;
    },
    async evaluate_assistant(id_card) {
      let result = await this.$http.post("pertem_assis_teach.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.pertem_assis_teach_id_card = result.data;
      let result_assistant = await this.$http.post("assistant_teacher.php", {
        ApiKey: this.ApiKey,
        assistant_teacher_id_card: id_card
      });
      this.assistant_teachers = result_assistant.data;
      let result_assistant_teacher_report = await this.$http.post(
        "assistant_teacher_report.php",
        {
          ApiKey: this.ApiKey,
          assistant_teacher_re_id_card: id_card
        }
      );
      this.result_assistant_teacher_reports =
        result_assistant_teacher_report.data;
      this.evaluate_assistantdialog = true;
    },
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    times_s() {
      let times = this.pertem_assis_teach_id_card.last_time;
      let result;
      if (times == "1") {
        result = 2;
      } else if (times == "2") {
        result = 3;
      } else if (times == "3") {
        result = 4;
      } else if (times == "4") {
        result = "ครบการประเมิน";
      } else {
        result = 1;
      }
      return result;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    color() {
      return "indigo darken-4";
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  },
  components: { Assistant_teacher_bar }
};
</script>
